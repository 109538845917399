export const HOME = {
  role: `PhD Candidate`,
  college: `University of Maryland, College Park, MD`,
  intro: `I am a predoctoral associate of research at Duke University and a PhD candidate specializing in race and ethnic politics at the University of Maryland, College Park.`,
  publications: `Research`,
  publicationsList: [{
    date: ' Advances in Political Psychology 2022',
    title: 'The Role of Race in Political Attitudes among the Non-Religious',
    subtitle: 'with Janelle Wong',
    path: 'https://onlinelibrary.wiley.com/doi/10.1111/pops.12849',
    download: false
  },
  {
    date: 'Working Papers',
    title: 'Black Racial Grievance and Black American Identity',
  },
  {
    date: 'Working Papers',
    title: 'Operationalizing Black Racial Grievance for Political Behavior',
  }
  ]
}